import React from 'react'
import './Footer.css'

const Footer = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    
    return (
        <div className="footerContainer"><hr></hr>
            <div className="footer">
                <p>Swan © 2017 - {currentYear}</p>
            </div>
        </div>
    )
}

export default Footer;
